
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;  /* fallback for old browsers */
  font-size: 14px;
  color: #445367;
}

html, body, #root, .App {
  height: 100% !important;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.custom-button{
  text-align: center;
  margin: 40px;

  border-radius: 50px;
  color:#fff;
  font-size: 30px;
  /*border: 2px solid #000;*/
  background: #00d2ff;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #928DAB, #00d2ff);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #928DAB, #00d2ff); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

a, a:hover{
  text-decoration: none !important;
}

.header{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.navbar{
  background-color: #fff;
}

/* HOME PAYLINK */
.first-section{
  background: linear-gradient(#4FD2DF, #67EA95);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}


.sidebar-nav {
  position: absolute;
  top: 0;
  width: 300px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-nav li {
  text-indent: 20px;
  line-height: 40px;
}

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: #999999;
}

.sidebar-nav li a:hover {
  text-decoration: none;
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none;
}

.sidebar-nav > .sidebar-brand {
  height: 65px;
  font-size: 18px;
  line-height: 60px;
}

.sidebar-nav > .sidebar-brand a {
  color: #999999;
}

.sidebar-nav > .sidebar-brand a:hover {
  color: #fff;
  background: none;
}

.shop img {
  margin: 35px 0;
}

.shop-info {
  padding: 18px 0;
  font-size: 17px;
}

.welcome {
  font-size: 25px;
}

.dashboard {
  padding-top: 4rem;
  line-height: normal;
}

.btn-orange {
  background: #EA5D27;
  color: white;
  margin: 12px 0;
}

.btn-perfil {
  color: #24B47E;
  margin: 10px 0;
  border: 1px solid;
  font-size: 15px;
}

.card {
  background-color: #E1FFFB;
  border-radius: 20px;
  margin: 15px 0;
  padding: 23px;
  min-height: 115px;
  border: none;
}

.card h3 {
  color: #24B47E;
  font-size: 20px;
}

.orders {
  font-size: 26px;
}

thead {
  background-color: #F3F3F3;
  font-weight: bold;
}

.nav-pills>li.active>a.pending,
.nav-pills>li.active>a.pending:focus,
.nav-pills>li.active>a.pending:hover {
  background-color: orange;
  color: white;
  font-weight: bold;
}

.nav-pills>li.active>a.paid,
.nav-pills>li.active>a.paid:focus,
.nav-pills>li.active>a.paid:hover {
  background-color: #24b47e;
  color: white;
  font-weight: bold;
}
.table>tbody>tr:hover{
  background-color: #E1FFFB;
}




/*LANDING*/

.landing-header{
  background: linear-gradient(142deg, rgba(79,210,223,1) 0%, rgba(103,234,149,1) 100%);
  color: white;
  padding: 5% 0;
}
.landing-header .title{
  font-size: 48px;
  font-weight: bold;
}
.landing-header p{
  font-size: 20px;
}
.landing-header img{
  margin-bottom: -220px;
}
.btn-white{
  background-color: white;
  color: #59dcbe;
  font-size: 18px;
  border-radius: 10px;
  margin: 12px 0;
  font-weight: bold;
}
.landing .content{
  margin-top: 220px;
}
.landing .content .subtitle{
  font-size: 17px;
  padding: 1%;
}
.landing .content p{
  font-size: 16px;
  padding: 1%;
}
.landing .section{
  margin-bottom: 3rem;
}
.line{
  border-top: 7px solid #5BDCAF;
  padding: 0rem 5rem;
}
.landing h3 {
  text-transform: uppercase;
  font-weight: bold;
}
i.check{
  color: #5BDCAF;
}
.landing .how-use{
  background: linear-gradient(142deg, rgba(79,210,223,1) 0%, rgba(93,224,179,1) 100%);
  padding: 11% 0;
}
.landing .how-use h3{
  color: white;
  text-transform: uppercase;
  font-weight: bold;
}
.bg-girl{
  background: url("/img/girl1.png") no-repeat;
  background-size: cover;
  background-position: center;
}
.card-header {
  background-color: transparent;
  border-bottom: 3px solid white;
}
.card-body{
  color: #445367;
  background-color: rgba(255, 255, 255, 0.55);
}
.card-header button{
  color: white;
  font-size: 15px;
  font-weight: bold;
}
.card-header button:hover{
  color: white;
  text-decoration: none;
}

.how-works, .how-start, .how-ready, .benefits{
  margin-top: 8%;
}

.landing .steps{
  font-size: 35px;
  color: #EA5D27;
  font-weight: bold;
  box-shadow: 0px 7px 6px #8080804a;
  width: 95px;
  line-height: 95px;
  border-radius: 50%;
  text-align: center;
}
.how-works p{
  font-size: 18px;
}

.how-start p{
  font-size: 16px;
  padding: 1%;
}
.how-ready{
  background: url("/img/footer_background.png") no-repeat;
  background-size: cover;
  background-position: center;
  background-position-y: 0;
}
.benefits-card{
  box-shadow: 0px 11px 7px rgba(184, 184, 184, 0.66);
  padding: 4%;
  min-height: 388px;
}
.benefits{
  margin-bottom: 5%;
}

/*********************************************************************************/
/* END LANDING */

.orders-table {
  width: 100%;

}

.orders-table tr{
  width: 100%;
}

.order-field {
  display: flex;
  text-align: left;
}

.order-field .labels {
  text-align: left;
  width: 50%;
}

.order-field .labels label {
    margin-bottom: 0;
}

.order-field .values {
  text-align: right;
  width: 50%;
}

.order-field .values p {
  margin-bottom: 0
}

@media screen and (max-width: 600px) {
  .only-desktop {
    display: none;
  }

  .orders-table tr td, .orders-table tr th {
    width: 25%;
    height: 2em;
    overflow: hidden;
  }

  .orders-table tr td p, .orders-table tr td span {
    margin-bottom: .5em;
    text-transform: capitalize;
  }
}

@media screen and (min-width: 600px) {
  .only-mobile {
    display: none;
  }
}

.dashboard-curtain.active {
  z-index: 100000;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: white;
}

/* ----------------------------------------------------------------------- */
/* Payment Instructions Style */

.head-instructions{
  background: linear-gradient(122deg, rgba(79,210,223,1) 0%, rgba(103,234,149,1) 100%);
  color: white;
  padding: 5% 0 0 0;
  text-align: center;
}
.head-instructions h1{
  margin: 10px 0 35px 0;
}
.head-instructions h4{
  margin: 28px 0;
}
.order{
  border: 2px solid white;
  border-radius: 15px;
  padding: 0 5px;
}
/* Steps new */
.active{
  border-bottom: 10px solid white;
  font-weight: bold;
  font-size: 19px;
}
.step{
  color: white;
  font-size: 18px;
}
.step-content{
  min-height: 360px;
}
.btn-orange{
  background-color: #EA5D27;
  font-size: 18px;
  border-radius: 10px;
  color: white;
}
.purple a,.purple i{
  color: #742284 !important;
  font-size: 15px;
  border-radius: 10px;
  font-weight: bold;
  padding: 2px;
  margin-top: 6px;
}
.turqueza a,.turqueza i{
  color: #00a6a6 !important;
  font-size: 15px;
  border-radius: 10px;
  font-weight: bold;
  padding: 2px;
  margin-top: 6px;
}

.boton{
  padding: 0 5%;
}
.boton a{
  color: white;
}
.vertical-center {
  min-height: 100%;
  display: flex;
  align-items: center;
}
.section-cards{
  margin: 2% 0;
}
.options{
  margin: 2%;
}
.info{
  padding: 16px 0;
}
.all-options{
  padding-bottom: 1rem;
}
.option-card{
  border: 2px solid;
  border-radius: 20px;
  padding: 1rem;
}
i.gold{
  color: goldenrod;
}
.option-card h6{
  margin-top: 5px;
  font-size: 18px;
}
/* Buttons */
.btn-finance {
  background: #21a574;
  color: white !important;
  padding: 2px 2rem;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 8px;
  border-radius: 8px;
}
.btn-right{
  padding: 0;
  margin-top: 8px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}
/* Options colors */
.option-card.green{
  border-color: #24B47E;
}
.option-card.green h6{
  color: #24B47E;
}
.option-card.green a{
  color: #24B47E;
}
.option-card.blue{
  border-color: #39BCC8;
}
.option-card.blue h6{
  color: #39BCC8;
}
.option-card.blue a{
  color: #39BCC8;
}
.option-card.orange{
  border-color: #EA5D27;
}
.option-card.orange h6{
  color: #EA5D27;
}
.option-card.orange a{
  color: #EA5D27;
}
.option-card.purple{
  border-color: #742284;
}
.option-card.purple h6{
  color: #742284;
}
.option-card.purple a{
  color: #742284;
}

/* End colors */
.coming-soon{
  position: relative;
}
.coming-soon .option-card{
  opacity: 0.3;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #445367;
  font-size: 20px;
  font-weight: bolder;
  text-shadow: -1px 4px 8px white;
}
.credit-card{
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.31);
  padding: 5%;
  margin-bottom: 15px;
}
.credit-card img{
  margin-bottom: 5px;
}
.credit-card i{
  color: #24B47E;
}
.count-number{
  font-size: 17px;
  font-weight: bold;
}
.MuiExpansionPanel-rounded{
  background-color: transparent !important;
}
.MuiIconButton-root {
  color: white !important;
}
.makeStyles-heading-116{
  color: white;
  font-weight: bold !important;
}
.MuiTypography-body1{
  color: white;
}
.MuiPaper-elevation1 {
  box-shadow: 0px 2px 1px -1px rgba(255, 255, 255, 0.2), 0px 1px 1px 0px rgba(255, 255, 255, 0.14), 0px 1px 3px 0px rgba(255, 255, 255, 0.12) !important;
}
.dashboard-curtain.active {
  top: 0;
  z-index: 100000;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: white;
}
.footer{
  background-color: #ececec;
  padding: 1% 0;
}
.animation{
  transition-duration: 0.5s;
  animation: winanim 0.5s ;
  -webkit-backface-visibility:visible;
  backface-visibility:visible;
}

@keyframes winanim {
  0%{opacity:0;transform:scale3d(.3,.3,.3)}
  50%{opacity:1}
}

/* ----------------------------------------------------------------------- */
/* Payment register Style */
.bg-form{
  background: white;
  padding: 5%;
}
.form-pay h4{
  text-align: center;
  color: #24B47E;
}
.form-pay hr{
  border-color: #24B47E;
}
.form-pay p.description{
  text-align: center;
  color: #24B47E;
  border: 1px solid #24B47E;
  border-radius: 10px;
  padding: 5px;
}
.MuiFormControl-root{
  width: 100%;
}
.btn-form{
  background: #21a574;
  color: white;
}
.btn-form:hover{
  background-color: #1a7f5a;
  color: white;
}
.image-preview{
  max-width: 300px;
}

@media only screen and (max-width: 600px) {
  /*.section-cards {
    min-height: 706px;
  }*/
  .footer {
    padding: 11% 0;
  }

  .sbs {
    width: 100%;
  }

  .image-preview {
    max-width: 70%;
  }

  .landing .steps {
    width: 50px;
    line-height: 50px;
  }
  .how-works img{
    width: 100%;
  }
  .landing-header .title {
    font-size: 40px;
    margin: 15% 0 0;
  }
  img.bg-profile{
    height: 0;
  }
}

/* MENU */
.menu{
  animation: fadein 500ms;
}

/* FOOTER  */
.main-footer {
  padding: 4em 0;
  border-top: 1px solid rgba(0,0,0,.1);
}
.main-footer__title {
  font-weight: 800;
  padding-bottom: 1rem;
}
.main-footer__nav a {
  color: #445367;
  display: block;
  padding: .3rem 0;
}
.main-footer__phone {
  color: #445367;
  font-size: 1.2em;
}
.main-footer__social {
  display: flex;
  align-items: center;
  padding-top: 1rem;
}
.main-footer__social-item {
  color: #fff;
  background: #24b47e;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn:disabled {
  opacity: 0.35;
}
.collapse.in {
  display: block !important;
}
.fa-question-circle {
  cursor: pointer;
}
.btn-activate{
  color: white !important;
  border-radius: 10px;
}
.btn-activate.green{
  background-color: #00b779;
}
.btn-activate.purple{
  background-color: #742284;
}
.btn-activate.turqueza{
  background-color: #00a6a6;
}
.add{
  color: #00b779 !important;
}
.p-2 {
  border-radius: 10px;
}
img.bg-profile{
  height: 20%;
}
.modal-detail{
  padding: 25px 5%;
}
.modal-buttons {
  padding: 3px 5%;
}